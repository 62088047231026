module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-NBSM9JL","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","staging","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBSM9JL","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            platform: 'gatsby',\n            userid: window.localStorage.getItem('uid'),\n            insurance_company_id: window.localStorage.getItem('company_id'),\n            representative_company_id: window.localStorage.getItem(\n              'representative_company_id'\n            )\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"standalone","icon":"./src/images/icons/plusBaton_favicon.png","legacy":true,"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d7fb0a1e384061f98076a03a4d2d03c9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
